import React from 'react';
import { PopoverProps } from "@material-ui/core/Popover";

const alnum = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-';

function randChar() {
    return alnum[Math.trunc(Math.random() * alnum.length)];
}

function randId(){
    let s = '';
    for (let i=0; i<8; i++) {
        s += randChar();
    }
    return s;
}

export interface PopupStateOpts {
    disableHover?: boolean;
}

export class PopupState {
    id: string;
    anchor: HTMLElement | null;
    setAnchor: React.Dispatch<HTMLElement | null>;
    opts: PopupStateOpts;
    constructor(id: string, anchor: HTMLElement | null, setAnchor: React.Dispatch<HTMLElement | null>, opts: PopupStateOpts={}) {
        this.id = id;
        this.anchor = anchor;
        this.setAnchor = setAnchor;
        this.opts = opts;
    }

    show(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.preventDefault();
        const activeElement = document.activeElement as any as { blur: () => void };
        if (activeElement.blur) activeElement.blur();
        this.setAnchor(event.currentTarget);
    }

    hide(event?: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setAnchor(null);
    }

    get isOpen(): boolean {
        return Boolean(this.anchor);
    }

    get anchorProps(): any {
        const props = {
            'aria-owns': this.id,
            'aria-describedby': this.id,
            'aria-haspopup': true,
        };
        if (!this.opts.disableHover) {
            Object.assign(props, {
                onMouseEnter: this.show.bind(this),
                onMouseLeave: this.hide.bind(this),
            });
        }
        return props;
    }

    get popoverProps(): PopoverProps {
        const c = {
            id: this.id,
            open: this.isOpen,
            style: { pointerEvents: 'none' },
            anchorEl: this.anchor,
            onClose: () => this.hide(),
        };
        return c as PopoverProps;
    }
}