import React from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import { Backend } from "./backend/Backend";
import { Redirect } from "react-router-dom";

export const Bar: React.FC<{ children: any }> = ({ children })=> {
    const [state, setState] = React.useState<{redirect?: string}>({});

    if (state.redirect) {
      return <Redirect to={state.redirect}/>;
    }

    const logout = async () => {
      await Backend.instance.logout();
      setState({redirect: '/login'});
    }

    return <AppBar>
        <Toolbar>
            {children}
            <div className="grow"></div>
            <Button className="header-button" variant="contained" onClick={logout}>Sign Out</Button>
        </Toolbar>
    </AppBar>;
  }