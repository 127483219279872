import React from "react";
import { Button, TextField, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { Backend } from "./backend/Backend";
import { Redirect } from "react-router";
import anime from 'animejs';

interface LoginProps {
    from?: Location | string;
}

interface State {
    username?: string;
    password?: string;
    error?: string;
    loggedIn?: boolean;
}

export const LoginPage: React.FC<LoginProps> = ({ from }) => {
    return <main className="center no-bar login">
        <div className="logo"></div>
        <h1>Hello!</h1>
        <h3>Please login to continue:</h3>
        <LoginForm {...{from}}/>
    </main>
};

export const LoginForm: React.FC<LoginProps> = ({ from }) => {
    const [values, setValues] = React.useState<State>({});
    const [forgotenDialogShown, showForgotten] = React.useState<boolean>(false);

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [name]: event.target.value});
    };

    if (values.loggedIn) {
        return <Redirect to={from || '/reports/'}/>;
    }

    const login = async () => {
        if (values.username && values.password) {
            try {
                await Backend.instance.login(values.username, values.password);
                setValues({ ...values, loggedIn: true });
            } catch (e) {
                anime({
                    targets: ['#loginForm'],
                    keyframes: [-40, 40, -20, 20, 0].map(x => ({translateX: x})),
                    easing: 'easeInOutSine',
                    duration: 550,
                });

                if (e.isAxiosError) {
                    setValues({...values, error: e.response.data.message});
                }
            }
        }
    }

    const canLogin = [values.username, values.password].every(v => v && v.length);

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (canLogin) {
            login();
        }
    }

    const onForgottenClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        showForgotten(true);
    }

    return <form className="login" id="loginForm" onSubmit={submit}>
        <TextField
            id="username"
            variant="outlined"
            label="Username"
            InputLabelProps={{ disableAnimation: true }}
            error={!!values.error}
            onChange={handleChange('username')}/>

        <TextField
            id="password"
            variant="outlined"
            type="password"
            label="Password"
            value={values.password}
            InputLabelProps={{ disableAnimation: true }}
            onChange={handleChange('password')}
            error={!!values.error}
            helperText={values.error}/>

        <div className="controls">
            <a className="forgot-password" href="#" onClick={onForgottenClick}>Forgot password</a>
            <div className="grow"/>
            <Button
                color="primary"
                variant="contained"
                disabled={!canLogin}
                type="submit">Login</Button>
        </div>

        <Dialog open={forgotenDialogShown}
            onClose={() => showForgotten(false)}>
            <DialogTitle>Forgot your password?</DialogTitle>
            <DialogContent>
                Please contact <a href="mailto:info@workahead.co">info@workahead.co</a> to reset your password.
            </DialogContent>
            <DialogActions>
                <Button onClick={() => showForgotten(false)}>OK</Button>
            </DialogActions>

        </Dialog>
    </form>;
}
