import qs from 'querystring';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { SurveyReport } from '../model/report';


interface Session {
    token: string;
    refresh: string;
}


class SessionStorage {
    private data: Session | null = null;
    private static key = 'session';

    private constructor(data: Session) {
        this.data = data;
    }

    save(s: Session) {
        this.data = s;
        window.localStorage.setItem(SessionStorage.key, JSON.stringify(s));
    }

    get exists(): boolean {
        return this.data !== null;
    }

    get token(): string | null {
        return this.data ? this.data.token : null;
    }

    get refreshToken(): string | null {
        return this.data ? this.data.refresh : null;
    }

    clear() {
        window.localStorage.removeItem(SessionStorage.key);
    }

    static load(): SessionStorage {
        const saved = window.localStorage.getItem(SessionStorage.key);
        const data = saved ? JSON.parse(saved) : null;
        return new SessionStorage(data);
    }
}

export interface ReportResponse {
    total: number;
    reports: SurveyReport[];
}

export class Backend {
    private static _instance: Backend;
    private session: SessionStorage;

    constructor() {
        this.session = SessionStorage.load();
    }

    private get headers(): any {
        return (this.session.exists) ? { Authorization: this.session.token } : {};
    }

    private async tryRecover(e: any) {
        if (e.isAxiosError) {
            const ae = e as AxiosError;
            if (ae.response &&
                ae.response.data &&
                ae.response.status === 401 &&
                (/expired/i).test(ae.response.data.message)) {

                await this.refresh();
                return;
            }
        }
        throw e;
    }

    private async get(url: string): Promise<AxiosResponse> {
        try {
            return await axios.get(url, { headers: this.headers });
        } catch (e) {
            await this.tryRecover(e);
            return await axios.get(url, { headers: this.headers });
        }
    }

    private async post(url: string, data: any): Promise<AxiosResponse> {
        try {
            return axios.post(url, data, { headers: this.headers });
        } catch (e) {
            await this.tryRecover(e);
            return axios.post(url, data, { headers: this.headers });
        }
    }

    async checkAuth(): Promise<boolean> {
        if (!this.session.exists) return false;
        try {
            await this.get('/api/auth/ping');
        } catch (_) {
            return false;
        }
        return true;
    }

    async login(username: string, password: string) {
        const response = await this.post('/api/auth/login', { username, password });
        this.session.save(response.data);
    }

    get loggedIn(): boolean {
        return this.session.exists;
    }

    logout() {
        return this.session.clear();
    }

    async refresh() {
        const headers = { Authorization: this.session.refreshToken };
        const response = await axios.post('/api/auth/refresh', {}, { headers });
        this.session.save(response.data);
    }

    async getReports(limits?: {skip?: number, limit?: number}): Promise<ReportResponse> {
        const query = qs.stringify(limits || {});
        const response = await this.get(`/api/reports/?${query}`);
        return response.data;
    }

    async getReport(uuid: string): Promise<SurveyReport> {
        const response = await this.get(`/api/reports/${uuid}`);
        return response.data;
    }

    static get instance(): Backend {
        if (!Backend._instance) {
            Backend._instance = new Backend();
        }
        return Backend._instance;
    }
}