import React from 'react';
import { AxiosError } from 'axios';
import { Redirect } from 'react-router';

export class NotFoundError {}

export class AuthError {}

interface CatchState {
    redirect?: string;
}

export default class Catch extends React.Component<{}, CatchState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidCatch(err: any) {
        if (err.isAxiosError) {
            const ae = err as AxiosError;
            if (ae.response &&
                ae.response.data &&
                ae.response.status === 401) {
                    this.setState({ redirect: '/login' });
            }
        }
    }

    render(): React.ReactNode {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        return this.props.children;
    }
}