import React from 'react';
import { Toolbar, Button, AppBar, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import './App.scss';
import { LoadReport, ListReports, ReportPage } from './reports/Report';
import { BrowserRouter, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { LoginForm, LoginPage } from './Login';
import { Backend } from './backend/Backend';
import Catch from './Catch';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#2c78f6",
        light: "#7caaff",
        dark: "#00b0bb",
      },
      secondary: {
        main: "#8fed92",
        light: "#c2ffc3",
        dark: "#bcba63",
      }
    },
    typography: {
      fontFamily: 'Stolzl',
    }
  }
);

const Bar: React.FC<{ title: string }> = ({ title })=> {
  const [state, setState] = React.useState<{redirect?: string}>({});

  if (state.redirect) {
    return <Redirect to={state.redirect}/>;
  }

  const logout = async () => {
    await Backend.instance.logout();
    setState({redirect: '/login'});
  }

  return <AppBar>
          <Toolbar>
            <img src="/Logo.png" height="23px" alt=""/>
            <h3>{title}</h3>
            <div className="grow"/>
            <Button className="header-button" variant="contained" onClick={logout}>Sign Out</Button>
          </Toolbar>
        </AppBar>;
}

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
    <div className="App">
        <Router/>
    </div>
    </MuiThemeProvider>
  );
}

const Page: React.FC<{title: string}> = ({title, children}) => {
  return <Catch>
    <Bar title={title}/>
    <main className="mui-fixed content">
      {children}
    </main>
  </Catch>
}

type RouteFC<T={}> = React.FC<RouteComponentProps<T>>;

const Home = () => {
    const path = Backend.instance.loggedIn ? '/reports/' : '/login';
    return <Redirect to={path}/>;
}

const Router: React.FC = () => {
  return <BrowserRouter>
    <Route exact path="/" render={Home}/>
    <Route exact path="/login" render={() => <LoginPage/>}/>
    <Route exact path="/reports/" render={() => <Page title="Reports"><ListReports/></Page>}/>
    <Route path="/reports/:id" render={(prop) => <ReportPage id={prop.match.params.id}/>}/>
   </BrowserRouter>
}

export default App;
