import React from "react";
import { FC } from "react";
import { Question, Framework } from "../model/report";
import { Chart } from "./Chart";
import { PopupState } from "./Popup";
import { Popover, Typography, InputAdornment, TextField, Fade, Popper } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';

interface QuestionProps {
    question: Question;
    breakdown?: boolean;
}

export const QuestionBlock: FC<QuestionProps> = ({ question, breakdown }) => {
    return <div className="question">
        <QuestionTitle title={question.title}/>
        <Status {...{question}}/>
        <Chart {...{question, breakdown}}/>
        <Comments {...{question}}/>
    </div>
};

/**
 * When possible, split the title by sentences in two parts, so that the first part
 * is at most maxLen characters long.
 */
function splitTitle(text: string, maxLen: number=25): string[] {
    if (text.length < maxLen) {
        return [text];
    }

    const tail = text.replace(/([?!.]+) /, '$1\0').split('\0');
    const pop = () => tail.splice(0, 1)[0];
    let head = pop();

    while (tail.length) {
        const next = tail[0];
        if (head.length + next.length + 1 > maxLen) {
            break;
        }
        head += ' ' + pop();
    }
    return [head, tail.join(' ')];
}

const QuestionTitle: FC<{ title: string }> = ({ title }) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const popup = new PopupState(`more-${title}`, anchor, setAnchor, { disableHover: true });

    const [mainTitle, moreTitle] = splitTitle(title);
    let elems = [
        <h5>{mainTitle}</h5>
    ];

    if (moreTitle && moreTitle.length) {
        elems = [
            <h5>{mainTitle} <a href="#" {...popup.anchorProps}
                onClick={(e) => e.preventDefault()}
                onMouseEnter={(e) => { e.preventDefault(); popup.show(e)}}
                onMouseLeave={(e) => { e.preventDefault(); popup.hide() }}>See more</a></h5>,
            <Popover {...popup.popoverProps}
                className="popover see-more"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <p>{mainTitle} {moreTitle}</p>
            </Popover>
        ];
    }

    return <div className="title">
        {elems}
    </div>
};

const Status: FC<{ question: Question }> = ({ question }) =>
    <div className="status">
        <InfoButton {...{question}}/>
        {/* <div className="grow total">{question.answers.map(a => a.total).reduce((a, b) => a+b, 0)} responses</div> */}
        <Frameworks {...{question}}/>
    </div>


function InfoIcon(attrs: any) {
    return <img src="/img/i-icon.svg" width="20" className="info icon" {...attrs} alt=""></img>
}

const Info: FC<{ question: Question }> = ({ question }) => {
    return <div>
    <InfoIcon/>
    <h5>{question.progress.value}</h5>
    <Typography>{question.progress.description}</Typography>
    </div>;
};

const InfoButton: FC<{ question: Question }> = ({ question }) => {
    const popup = new PopupState(`info-${question.id}`, ...React.useState<HTMLElement | null>(null));

    return <>
    <InfoIcon {...popup.anchorProps}/>
    { question.progress ?
    <Popover {...popup.popoverProps}
        className="popover info"
        TransitionComponent={Fade}
        anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            transformOrigin={{
                vertical: 10,
                horizontal: 10,
            }}>

        <Info {...{question}} />
    </Popover>: null }
    </>;
};

function FrameworkIcon(attrs: any) {
    const elemAttrs: any = {...attrs};
    delete elemAttrs.name;
    const { name } = attrs;
    return <span className={`icon framework ${name}`} {...elemAttrs}>{name}</span>
}

const FrameworkPopup: FC< { questionId: string, framework: Framework } > = ({ questionId, framework }) => {
    const { name, description, title, subtitle } = framework;
    const popup = new PopupState(`fw-${questionId}-${name}`, ...React.useState<HTMLElement | null>(null));

    return <>
    <FrameworkIcon name={name} {...popup.anchorProps}/>

    { description ? <Popover {...popup.popoverProps}
                TransitionComponent={Fade}
                className="popover framework"
                anchorOrigin={{
                    vertical: -20,
                    horizontal: 69,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
            <div>
                <FrameworkIcon name={name}/>
                <h5>{title || name}</h5>
                { subtitle ? <h6>{subtitle}</h6> : null}
                <Typography>{description}</Typography>
            </div>
        </Popover> : null
    }
    </>
};

const Frameworks: FC<{ question: Question }> = ({ question }) => {
    const { frameworks } = question;
    const elems = ( frameworks || []).map((framework, i) =>
        <FrameworkPopup key={i} questionId={question.id} {...{framework}}/>
    );
    return <div className="frameworks">{elems}</div>;
}

const Comments: FC<{ question: Question }> = ({ question }) => {
    const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

    const show = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    };

    const hide = () => {
        setAnchorEl(null);
    }

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? `comment-${question.id}-popup` : undefined;

    return <>
        <TextField className="comment"
            variant="outlined"
            placeholder="Write a comment..."
            type="text"
            InputProps={{
                endAdornment: <InputAdornment position="end"><SendIcon opacity={0.5} color="primary"/></InputAdornment>
            }}
            inputProps={{
                onKeyDown: (event: any) => {
                    event.preventDefault();
                    show(event);
                }
            }}>
        </TextField>
        <Popper id={id} open={isOpen} anchorEl={anchorEl} transition className="tooltip">
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={1000} onEntered={() => setTimeout(hide, 1000)}>
                    <Typography>Commenting feature is disabled</Typography>
                </Fade>
            )}
        </Popper>
    </>;
}