import React, { FC, useState, ChangeEvent, useEffect } from "react";
import { Backend, ReportResponse } from "../backend/Backend";
import {
    FormControlLabel,
    Switch,
    Table, TableRow, TableCell, TableHead, TableBody, TableFooter, TablePagination, Typography
    } from "@material-ui/core";
import './Report.scss';
import { SurveyReport, ReportSection, Question } from "../model/report";
import { QuestionBlock } from "./Question";
import Catch from "../Catch";
import { Bar } from "../Bar";
import { Redirect } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Condition } from "./Condition";

const Section: FC<{ section: ReportSection }> = ({ section }) => {
    const [state, setState] = useState<{breakdown?: boolean}>({});

    const switchBreakdown = (event: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, breakdown: event.target.checked });
    }

    const { questions } = section;

    function hasBreakdown(q: Question) {
        const { breakdown } = q.answers[0];
        return breakdown && breakdown.length > 1;
    }
    return <section>
        <p className="header">
            <h2>{section.title}</h2>
            <div className="grow"/>

            <Condition cond={section.questions.some(hasBreakdown)}>
            <div className="breakdown-toggle">
                <FormControlLabel
                    control={
                        <Switch checked={state.breakdown} onChange={switchBreakdown}/>
                    }
                    label="See breakdown"
                    labelPlacement="start"
                    />
            </div>
            </Condition>
        </p>
        <p className="questions">
        {
            questions.map((q) => <QuestionBlock breakdown={state.breakdown} question={q}/>)
        }
        </p>
    </section>
};


const highlight = (value: any) => <span className="highlight">{value}</span>

const ReportSummary: FC<{ report: SurveyReport }> = ({ report }) => {
    const { header } = report;
    return <section className="summary">
        <h2>Report Summary: {highlight(header.organization)}</h2>
        <p>
            <div className="report-location">Location: {highlight(header.place)}</div>
            <div className="report-date">Date of survey: {highlight(header.date)}</div>
            <div className="report-respondents">Number of respondents: {highlight(header.respondents)}</div>
            { header.description ?
                <div className="report-type">Type of survey: {highlight(header.description)}</div>
                :
                null
            }
        </p>
    </section>;
}

export const Report: FC<{ report: SurveyReport }> = ({ report }) =>
    <div className="report">
        <ReportSummary {...{report}}/>
        {report.results.map((section, key) => <Section {...{section, key}}/>)}
    </div>;

export const LoadReport: FC<{ id: string }> = ({ id }) => {
    const [report, setReports] = useState<SurveyReport>();
    const [error, setError] = useState();

    useEffect(() => {
        if (!(error || report))
            Backend.instance.getReport(id).then(setReports).catch(setError);
    });

    if (error) throw error;

    if (report) {
        return <Report report={report}/>;
    }

    return null;
};

export const ReportPage: FC<{ id: string }> = ({ id }) => {
    const [ state, setState ] = React.useState<{ redirect?: string }>({});

    if (state.redirect) {
        return <Redirect to={state.redirect}/>;
    }

    const back = () => setState({ redirect: '/reports/' });

    return <Catch>
        <Bar>
            <h3 onClick={back} style={{ cursor: 'pointer' }}>
                <ArrowBackIcon className="icon"/> Go back
            </h3>
            <div className="logo"/>
        </Bar>
        <main className="mui-fixed content">
            <LoadReport id={id}/>
        </main>
    </Catch>
};

interface ReportTableState {
    reports?: ReportResponse;
    error?: any;
    page: number;
    limit: number;
}

export const ListReports = (_: any) => {
    const [state, setState] = useState<ReportTableState>({ limit: 25, page: 0 });

    const load = async (limit: number, page: number) => {
        const skip = limit * page;
        try {
            const reports = await Backend.instance.getReports({ skip, limit })
            setState({ ...state, limit, reports, page });
        } catch (error) {
            setState({ ...state, error });
        }
    }

    useEffect(() => { if (!(state.reports || state.error)) load(state.limit, 0); });

    const changePage = (event: any, newPage: number) => {
        load(state.limit, newPage);
    };

    const setLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const limit = parseInt(event.target.value, 10);
        load(limit, 0);
    }

    if (state.error) {
        throw state.error;
    }

    if (state.reports) {
        // if (state.reports.reports.length === 1) {
        //     return <Redirect to={`/reports/${state.reports.reports[0].uuid}`}/>;
        // }

        const { reports } = state;
        return <div className="report" style={{ alignItems: 'flex-start' }}>
        <p>
            <h4>Survey Reports</h4>
        </p>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Survey</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Respondents</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {reports.reports.map((rep, i) =>
                <TableRow key={`report-row-${i}`}>
                    <TableCell><a href={`/reports/${rep.uuid}`}>{rep.header.place}, {rep.header.date}</a></TableCell>
                    <TableCell>{rep.header.organization}</TableCell>
                    <TableCell>{rep.header.respondents}</TableCell>
                </TableRow>
            )}
            </TableBody>
            <TableFooter>
                <TablePagination
                    page={state.page || 0}
                    count={reports.total}
                    rowsPerPage={state.limit}
                    onChangePage={changePage}
                    onChangeRowsPerPage={setLimit}/>
            </TableFooter>
        </Table></div>
    }
    return null;
}
